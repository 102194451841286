import type { NextPageContext } from 'next';
import NextError from 'next/error';

function Error({ statusCode }: { statusCode: number }) {
  return <NextError statusCode={statusCode} />
}

Error.getInitialProps = ({ req, res, err }: NextPageContext) => {
  const statusCode = res?.statusCode || err?.statusCode || 404

  return { statusCode }
}

export default Error
